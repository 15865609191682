/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { isArray } from 'lodash'

import { renderHTML } from '../../lib/helpers'

import { Cont, FlexCols, FlexCol } from '../../styles/els'

import Embed from './embed'

const VideoWrapper = (props) => {
  const {
    embed,
    embedCode,
    contain,
    byPassSplash,
    toEnlarge,
    ratio,
    placeholder,
    ...otherProps
  } = props

  return embed || embedCode ? (
    <Embed
      src={embed}
      code={embedCode}
      contain={contain}
      byPassSplash={byPassSplash}
      toEnlarge={toEnlarge}
      placeholder={placeholder}
      ratio={ratio}
    />
  ) : null
}

export default VideoWrapper
