/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from 'theme-ui'
import { atom, useAtom } from 'jotai'
import { get, isArray, isString, over } from 'lodash'
import { css } from '@emotion/react'

import { scrollY } from '../../styles/css'
import { Cont } from '../../styles/els'

import Contact from './blocks/contact'
import Login from './blocks/login'
import Menu from './blocks/menu'
import Popup from './blocks/popup'
import Try from './blocks/try'
import Video from './blocks/video'

const blocks = {
  contact: {
    layout: 'side',
    component: Contact,
  },
  login: {
    component: Login,
  },
  menu: {
    layout: 'top',
    component: Menu,
  },
  try: {
    component: Try,
  },
  video: {
    component: Video,
  },
  popup: {
    component: Popup,
  },
}

export const overlayAtom = atom(null)

const containerStyles = (layout) => {
  switch (layout) {
    case 'top':
    default:
      return null
  }
}

const parseOverlay = (overlay) => {
  if (!overlay) {
    return [null, null]
  }
  const overlayType = isString(overlay)
    ? overlay
    : isArray(overlay) && overlay.length > 0
    ? overlay[0]
    : null

  if (!blocks[overlayType] || !blocks[overlayType].component) {
    return [null, null]
  }

  if (isString(overlay) || overlay.length === 1) {
    return [overlayType, null]
  } else {
    return [overlayType, ...overlay.slice(1)]
  }
}

const OverlayWrapper = (props) => {
  const { type, data, onClose } = props

  const Component = blocks[type].component

  switch (blocks[type].layout) {
    case 'top':
      return (
        <div
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            maxHeight: '100vh',
            zIndex: 2001,
            backgroundColor: 'light',
            color: 'dark',
          }}
          css={css`
            ${scrollY}
          `}
        >
          <Component {...data} />
        </div>
      )
    case 'side':
      return (
        <div
          sx={{
            position: 'fixed',
            right: 0,
            top: 0,
            width: '90%',
            maxWidth: '500px',
            height: '100%',
            zIndex: 2001,
            backgroundColor: 'light',
            color: 'dark',
          }}
          css={css`
            ${scrollY}
          `}
        >
          <div sx={{ px: [5, 6], py: [7, null, 8] }}>
            <Component {...data} />
          </div>
        </div>
      )
    default:
    case 'panel':
      return (
        <div
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 2001,
            display: 'flex',
            flexDirection: 'column',
            p: 6,
          }}
          css={css`
            ${scrollY}
          `}
          onClick={onClose}
        >
          <div
            sx={{
              display: 'block',
              position: 'relative',
              backgroundColor: 'light',
              color: 'dark',
              borderRadius: 3,
              p: [5, 7],
              maxWidth: '880px',
              width: '100%',
              mx: 'auto',
              my: 'auto',
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Component {...data} />
          </div>
        </div>
      )
  }
}

const Overlays = (props) => {
  const { path } = props

  const [_overlay, setOverlay] = useAtom(overlayAtom)
  const [overlay, overlayData] = parseOverlay(_overlay)

  const onClose = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setOverlay(null)
  }

  useEffect(() => {
    setOverlay(null)
  }, [path])

  return overlay ? (
    <>
      <OverlayWrapper type={overlay} data={overlayData} onClose={onClose} />
      <div
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 2000,
          backgroundColor: 'b0',
          opacity: 0.8,
        }}
        onClick={onClose}
      />
    </>
  ) : null
}

export default Overlays
