/** @jsx jsx */
import React, { useMemo, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import RegionOverlayContent, { useOverlayContent } from '../shared/regions'

const query = graphql`
  query {
    wp {
      globalSettings {
        acfOptions {
          loginOverlayHeading
          loginOverlayIntro
          loginOverlayCtaText
          loginOverlayLinks {
            label
            link
            region
          }
        }
      }
    }
  }
`

const Login = (props) => {
  const overlayData = useStaticQuery(query)
  const contentData = useOverlayContent(
    get(overlayData, 'wp.globalSettings.acfOptions'),
    'login'
  )

  return <RegionOverlayContent gaPrefix="login" {...contentData} />
}

export default Login
