/** @jsx jsx */
import React, { useEffect, useMemo } from 'react'
import { jsx } from 'theme-ui'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { get, isArray, each } from 'lodash'
import { useSetAtom } from 'jotai'

import { overlayAtom } from '../overlays'

import { LinkTypes, addGAEvent } from '../../lib/helpers'

const SpanWrap = React.forwardRef((props, ref) => {
  const { as, asButton, buttonType, children, ...otherProps } = props
  const Component = asButton ? 'button' : as ? as : 'span'

  return asButton ? (
    <Component
      type={buttonType ? buttonType : null}
      sx={{ cursor: 'pointer' }}
      ref={ref}
      {...otherProps}
    >
      {children}
    </Component>
  ) : (
    <Component ref={ref} {...otherProps}>
      {children}
    </Component>
  )
})

const groupGAEvents = (props) => {
  const { to, gaEvents, type } = props

  const events = []

  if (gaEvents && isArray(gaEvents)) {
    events.push(gaEvents)
  }

  switch (type) {
    case LinkTypes.EMAIL:
      events.push([
        'email_click',
        {
          email: to,
        },
      ])
      break
    case LinkTypes.EXTERNAL:
      events.push([
        'externallink_click',
        {
          to,
        },
      ])
      break
    case LinkTypes.LOGIN:
      events.push(['login_click'])
      break
    case LinkTypes.POC:
      events.push(['poc_click'])
      break
    case LinkTypes.TRY:
      events.push(['try_click'])
      break
    case LinkTypes.CONTACT:
      events.push(['contact_click'])
      break
  }

  return events
}

const Link = React.forwardRef((props, ref) => {
  const {
    to: _to,
    children,
    type,
    data,
    asButton,
    as,
    buttonType,
    gaEvents: _gaEvents,
    ...otherProps
  } = props
  const setOverlay = useSetAtom(overlayAtom)
  const gaEvents = groupGAEvents(props)
  const triggerGAEvents = () => {
    each(gaEvents, ([eventKey, data]) => {
      addGAEvent(eventKey, data)
    })
  }

  const to =
    type === LinkTypes.POC ? '/for-you/proof-of-concept-in-a-day/' : _to
  const onClick =
    type === LinkTypes.TRY
      ? (e) => {
          e.preventDefault()
          e.stopPropagation()

          triggerGAEvents()

          setOverlay('try')
        }
      : type === LinkTypes.LOGIN
      ? (e) => {
          e.preventDefault()
          e.stopPropagation()

          triggerGAEvents()

          setOverlay('login')
        }
      : type === LinkTypes.CONTACT
      ? (e) => {
          e.preventDefault()
          e.stopPropagation()

          triggerGAEvents()

          setOverlay('contact')
        }
      : type === LinkTypes.VIDEO && data
      ? (e) => {
          e.preventDefault()
          e.stopPropagation()

          triggerGAEvents()

          setOverlay(['video', data])
        }
      : () => {
          triggerGAEvents()
        }

  return to ? (
    type === LinkTypes.EXTERNAL || type === LinkTypes.EMAIL ? (
      <OutboundLink
        ref={ref}
        href={to}
        target={type === LinkTypes.EXTERNAL ? '_blank' : null}
        rel="noopener"
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </OutboundLink>
    ) : (
      <GatsbyLink to={to} onClick={onClick} ref={ref} {...otherProps}>
        {children}
      </GatsbyLink>
    )
  ) : (
    <SpanWrap
      sx={{ cursor: onClick || get(otherProps, 'onClick') ? 'pointer' : null }}
      asButton={asButton}
      as={as}
      buttonType={buttonType}
      onClick={onClick}
      ref={ref}
      {...otherProps}
    >
      {children}
    </SpanWrap>
  )
})

Link.displayName = 'Link'

export default Link
