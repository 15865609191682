import React from 'react'
import { Provider as AtomProvider } from 'jotai'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'

const WrappedEl = ({ children }) => {
  return (
    <HubspotProvider>
      <AtomProvider>{children}</AtomProvider>
    </HubspotProvider>
  )
}

export default WrappedEl
