/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Buttons from '../buttons'

import { fillArea, floodBgImage } from '../../styles/css'
import { Cont, VStack } from '../../styles/els'
import { renderHTML } from '../../lib/helpers'

const headingVariant = (tier) => {
  switch (tier) {
    case 1:
      return 'bold_82'
    case 2:
      return 'bold_64'
    case 3:
      return 'bold_48'
    case 4:
    default:
      return 'bold_28'
  }
}

const subHeadingVariant = (tier) => {
  switch (tier) {
    case 1:
      return 'bold_64'
    case 2:
      return 'bold_48'
    case 3:
      return 'bold_28'
    case 4:
    default:
      return 'bold_19'
  }
}

const textVariant = (tier) => {
  switch (tier) {
    case 2:
      return 'default'
    case 1:
    default:
      return 28
  }
}

const Heading = ({ text, hTier, mx }) => {
  return (
    text && (
      <h3
        sx={{
          variant: `text.${headingVariant(hTier)}`,
          maxWidth: '820px',
          mx,
        }}
      >
        {renderHTML(text)}
      </h3>
    )
  )
}

const Subheading = ({ text, hTier, mx }) => {
  return (
    text && (
      <h4
        sx={{
          variant: `text.${subHeadingVariant(hTier)}`,
          color: 'b5',
          maxWidth: '720px',
          mx,
        }}
      >
        {renderHTML(text)}
      </h4>
    )
  )
}

const Paragraph = (props) => {
  const {
    heading,
    subheading,
    prefixSubheading = false,
    hTier,
    text,
    htmlText,
    tTier,
    tTint,
    align,
    mCenter,
    buttons,
    bTier,
    onDark,
    ...otherProps
  } = props

  const mx = mCenter || align === 'center' ? 'auto' : null

  return (
    <VStack
      sx={{
        textAlign: align ? align : null,
      }}
      space={6}
      {...otherProps}
    >
      <VStack space={hTier === 1 ? [4, 6] : hTier <= 4 ? [3, 5] : 0}>
        {prefixSubheading && (
          <Subheading text={subheading} hTier={hTier} mx={mx} />
        )}
        <Heading text={heading} hTier={hTier} mx={mx} />
        {!prefixSubheading && (
          <Subheading text={subheading} hTier={hTier} mx={mx} />
        )}
        {text || htmlText ? (
          <div
            sx={{
              variant: `textBlock.${textVariant(tTier)}`,
              maxWidth: '960px',
              color: tTint ? (onDark ? 'b5' : 'b3') : null,
              mx,
            }}
          >
            {htmlText ? (
              renderHTML(htmlText)
            ) : text ? (
              <p>{renderHTML(text)}</p>
            ) : null}
          </div>
        ) : null}
      </VStack>
      {buttons ? (
        <Buttons buttons={buttons} align={align} tier={bTier} />
      ) : null}
    </VStack>
  )
}

export default Paragraph
