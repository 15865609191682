import React, { useState, useEffect } from 'react'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import useId from 'react-use-uuid'

const HubspotComponent = (props) => {
  const { portalId, formId } = props
  const id = useId()
  const hubspotElID = `hubspot-el-${id}`

  const { loaded, error, formCreated } = useHubspotForm({
    portalId,
    formId,
    target: `#${hubspotElID}`,
  })

  return (
    <>
      {error ? (
        <span>Error loading form, please try again</span>
      ) : !loaded || !formCreated ? (
        <span>Loading form...</span>
      ) : null}
      <div id={hubspotElID} />
    </>
  )
}

const HubspotComponentWrapper = (props) => {
  const { portalId, formId } = props
  const [render, setRender] = useState(false)

  useEffect(() => {
    setRender(true)
  }, [])

  return render && portalId && formId ? (
    <HubspotComponent portalId={portalId} formId={formId} />
  ) : null
}

export default HubspotComponentWrapper
