/** @jsx jsx */
import React, { useMemo, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { isFinite, get } from 'lodash'
import { motion, useMotionValue } from 'framer-motion'
import { mapRange } from 'canvas-sketch-util/math'
import { rgba } from 'polished'

import Button from '../../button'
import Buttons from '../../buttons'
import { RatioBox } from '../../image'
import Link from '../../link'
import { Links, legalPages } from '../../header'
import Paragraph from '../../paragraph'
import VideoWrapper from '../../video-wrapper'

import {
  Cont,
  FlexCols,
  FlexCol,
  FlexColEmpty,
  HStack,
  VStack,
} from '../../../styles/els'
import { floodBgImage } from '../../../styles/css'
import { valueFromTheme } from '../../../styles/utils'

const Video = (props) => {
  const { video, embedCode } = props

  return (
    <VideoWrapper
      embed={video}
      embedCode={embedCode}
      ratio={embedCode ? 1 : null}
      byPassSplash={true}
    />
  )
}

export default Video
