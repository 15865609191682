import React from 'react'
import { css as csx } from 'theme-ui'
import { Global, css } from '@emotion/react'

import { fontFace } from './css'

import reset from './reset'

import RoobertMediumEOT from './typeface/Roobert-Medium.eot'
import RoobertMediumTTF from './typeface/Roobert-Medium.ttf'
import RoobertMediumWOFF from './typeface/Roobert-Medium.woff'
import RoobertMediumWOFF2 from './typeface/Roobert-Medium.woff2'

import RoobertBoldEOT from './typeface/Roobert-Bold.eot'
import RoobertBoldTTF from './typeface/Roobert-Bold.ttf'
import RoobertBoldWOFF from './typeface/Roobert-Bold.woff'
import RoobertBoldWOFF2 from './typeface/Roobert-Bold.woff2'

export default (props) => {
  return (
    <Global
      styles={(theme) => {
        return css`
          ${reset};

          ${fontFace(
            `'Roobert-Medium'`,
            {
              eot: RoobertMediumEOT,
              ttf: RoobertMediumTTF,
              woff: RoobertMediumWOFF,
              woff2: RoobertMediumWOFF2,
            },
            400
          )}

          ${fontFace(
            `'Roobert-Bold'`,
            {
              eot: RoobertBoldEOT,
              ttf: RoobertBoldTTF,
              woff: RoobertBoldWOFF,
              woff2: RoobertBoldWOFF2,
            },
            700
          )}

          html,
          body {
            width: 100%;
          }

          body {
            min-height: 100%;
            overflow-x: hidden;
            overflow-y: visible;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: 'grayscale';

            &,
            * {
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

              &:focus {
                outline: none;
              }
            }
          }

          a {
            color: inherit;
            text-decoration: none;

            &:focus {
              outline: none;
            }
          }
        `
      }}
    />
  )
}
