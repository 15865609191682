/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import Buttons from '../buttons'

import { Cont, HStack } from '../../styles/els'
import { LinkTypes } from '../../lib/helpers'

const query = graphql`
  query {
    wp {
      globalSettings {
        acfOptions {
          bannerShow
          bannerDetails {
            title
            subtitle
            text
            link
            linkText
          }
        }
      }
    }
  }
`

const Banner = (props) => {
  const data = useStaticQuery(query)
  const { bannerShow, bannerDetails } = useMemo(() => {
    return get(data, 'wp.globalSettings.acfOptions') || {}
  }, [data])

  return bannerShow && bannerDetails ? (
    <div
      sx={{
        backgroundColor: 'b7',
        color: 'black',
        py: 2,
      }}
    >
      <Cont>
        <HStack
          sx={{
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          space={3}
        >
          {bannerDetails?.title ? (
            <div sx={{ variant: 'text.bold_19' }}>{bannerDetails?.title}</div>
          ) : null}
          {bannerDetails?.subtitle ? (
            <div
              sx={{
                variant: 'text.regular_19',
              }}
            >
              {bannerDetails?.subtitle}
            </div>
          ) : null}
          {bannerDetails?.text ? (
            <div sx={{ variant: 'text.regular_19', color: 'b3' }}>
              {bannerDetails?.text}
            </div>
          ) : null}
          {bannerDetails?.link ? (
            <div
              sx={{
                width: ['100%', 'auto'],
                mt: [2, 0],
              }}
            >
              <Buttons
                tier={4}
                buttons={[
                  {
                    to: bannerDetails?.link,
                    type: LinkTypes.EXTERNAL,
                    color: 'blue',
                    label: bannerDetails?.linkText || 'Learn more',
                  },
                ]}
              />
            </div>
          ) : null}
        </HStack>
      </Cont>
    </div>
  ) : null
}

export default Banner
