/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { indexOf, shuffle } from 'lodash'

import Link from '../link'

import { gradientBg } from '../../styles/css'
import { valueFromTheme } from '../../styles/utils'

const padding = (tier, compact) => {
  switch (tier) {
    case 1:
      return {
        px: '40px',
        pt: '27px',
        pb: '33px',
      }
    case 2:
    case '2b':
      return {
        px: '36px',
        pt: '5px',
        pb: '13px',
      }
    case 4:
      return {
        px: '16px',
        pt: '5px',
        pb: '7px',
      }
    case 3:
    default:
      return {
        px: compact ? '10px' : '28px',
        pt: compact ? '0px' : '9px',
        pb: compact ? '4px' : '13px',
      }
  }
}

const textVariant = (tier) => {
  switch (tier) {
    case 1:
      return 'bold_82'
    case 2:
      return 'regular_48'
    case '2b':
      return 'bold_48'
    case 4:
      return 'regular_19'
    case 3:
    default:
      return 'regular_28'
  }
}

export const gradColors = ['aqua', 'blue', 'hot', 'magenta']

export const randomGradColor = (_colors = []) => {
  return gradColors[Math.floor(Math.random() * gradColors.length)]
}

const randomGradColors = () => {
  return shuffle([...gradColors])
}

export const randomGradColorArray = (length = 1) => {
  let arr = []
  let colorArr = randomGradColors()
  for (let i = 0; i < length; i++) {
    if (colorArr.length <= 0) {
      let hasFoundNewColorArr = false
      let tries = 0
      while (!hasFoundNewColorArr && tries < 5) {
        colorArr = randomGradColors()

        if (colorArr[0] != arr[arr.length - 1]) {
          hasFoundNewColorArr = true
        } else {
          tries += 1
        }
      }
    }
    arr.push(colorArr.shift())
  }
  return arr
}

const gradAB = (grad) => {
  switch (grad) {
    case 'aqua':
      return ['aquaGreen', 'aquaBlue']
    case 'magenta':
      return ['magentaPink', 'magentaPurple']
    case 'hot':
      return ['hotYellow', 'hotRed']
    case 'blue':
      return ['blueLight', 'blueDark']
    default:
      return null
  }
}

export const gradCss = (grad, theme) => {
  const colors = gradAB(grad)
  if (!colors) {
    return null
  }

  const a = valueFromTheme('colors', colors[0], theme)
  const b = valueFromTheme('colors', colors[1], theme)

  return gradientBg(a, b)
}

const Button = (props) => {
  const {
    to,
    asButton,
    buttonType,
    type,
    label,
    color: _color,
    textColor: _textColor,
    tier,
    compact,
    outline,
    ...otherProps
  } = props
  const { theme } = useThemeUI()

  const isGrad = indexOf(gradColors, _color) >= 0

  let color = isGrad
    ? null
    : _color && _color === 'tinted'
    ? 'b5'
    : _color && _color !== 'none'
    ? _color
    : 'light'
  let textColor = _textColor ? _textColor : 'dark'

  return (
    <Link
      to={to}
      type={type}
      asButton={asButton}
      buttonType={buttonType}
      sx={{
        position: 'relative',
        variant: `text.${textVariant(tier)}`,
        backgroundColor: color,
        ...padding(tier, compact),
        display: 'inline-block',
        borderRadius: '100px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        WebkitAppearance: 'none',
        border: 'none',
      }}
      css={
        isGrad
          ? css`
              ${gradCss(_color, theme)}
            `
          : null
      }
      {...otherProps}
    >
      <span
        sx={{
          position: 'relative',
          zIndex: 2,
          color: textColor,
          transition: 'color 0.3s ease-in-out',
        }}
      >
        {label ? label : 'Read more'}
      </span>
    </Link>
  )
}

export default Button
