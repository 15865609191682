/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import useDimensions from 'react-cool-dimensions'
import { GatsbyImage } from 'gatsby-plugin-image'
import { fill, isFinite } from 'lodash'
import { css } from '@emotion/react'

import { fitDimensionsToBox } from '../../lib/math-helpers'
import { fillArea } from '../../styles/css'
import { crossBrowserStyle } from '../../styles/utils'

import Img from './img'

const calcDimensions = (image, boxDimensions, contain) => {
  return fitDimensionsToBox(
    {
      width: Math.ceil(boxDimensions.width) + 1,
      height: Math.ceil(boxDimensions.height) + 1,
    },
    {
      width: image && image.width ? image.width : null,
      height: image && image.height ? image.height : null,
      ratio:
        image && image.aspectRatio && isFinite(image.aspectRatio)
          ? 1 / image.aspectRatio
          : null,
    },
    contain
  )
}

export const FillContainer = (props) => {
  const {
    size,
    contain,
    hAlign,
    vAlign,
    children,
    containedEl,
    ...otherProps
  } = props
  const { observe, width: boxWidth, height: boxHeight } = useDimensions()

  const { width, height } = calcDimensions(
    size,
    { width: boxWidth, height: boxHeight },
    contain
  )

  const hCentered = hAlign === 'left' || hAlign === 'right'
  const vCentered = vAlign === 'top' || vAlign === 'bottom'

  return (
    <div
      sx={{
        overflow: 'hidden',
      }}
      css={css`
        ${fillArea}
      `}
      ref={observe}
      {...otherProps}
    >
      <div
        sx={{
          position: 'absolute',
          left: hAlign === 'left' ? 0 : hAlign === 'right' ? null : '50%',
          right: hAlign === 'right' ? 0 : null,
          top: vAlign === 'top' ? 0 : vAlign === 'bottom' ? null : '50%',
          bottom: vAlign === 'bottom' ? 0 : null,
        }}
        style={
          isFinite(width) && isFinite(height)
            ? {
                width,
                height,
                ...crossBrowserStyle(
                  'transform',
                  `translate3d(
                    ${!hCentered ? `${width * -0.5}px` : 0},
                    ${!vCentered ? `${height * -0.5}px` : 0},
                    0)`
                ),
              }
            : null
        }
      >
        {containedEl ? containedEl : null}
        {children}
      </div>
    </div>
  )
}

const ImageFill = (props) => {
  const { image, alt, onImageLoad, gatsbyImageProps, ...otherProps } = props

  return (
    <FillContainer size={image} {...otherProps}>
      {image && image.src ? (
        <Img {...image} />
      ) : image && image.images ? (
        <GatsbyImage
          image={image}
          style={{ width: '100%', height: '100%' }}
          alt={alt ? alt : ''}
          onLoad={onImageLoad}
          {...gatsbyImageProps}
        />
      ) : null}
    </FillContainer>
  )
}

export default ImageFill
