/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../button'

import { HStack } from '../../styles/els'

const Buttons = (props) => {
  const { buttons, align, tier, ...otherProps } = props

  return buttons && buttons.length > 0 ? (
    <div>
      <HStack
        space={[0, 4]}
        sx={{
          alignItems: 'center',
          justifyContent: align === 'center' ? 'center' : null,
          mb: -4,

          '& > *': {
            mb: 4,
            width: ['100%', 'auto'],
            textAlign: ['center', 'inherit'],
          },
        }}
        {...otherProps}
      >
        {buttons.map((_button, _i) => {
          return (
            <Button
              key={_i}
              tier={tier}
              gaEvents={[
                'cta',
                {
                  type: _button.type,
                  to: _button.to,
                  text: _button.label,
                },
              ]}
              {..._button}
            />
          )
        })}
      </HStack>
    </div>
  ) : null
}

export default Buttons
