/** @jsx jsx */
import React, { useMemo, useEffect, useState } from 'react'
import { jsx } from 'theme-ui'
import Cookies from 'js-cookie'

import Paragraph from '../../paragraph'

import { VStack } from '../../../styles/els'

import { LinkTypes } from '../../../lib/helpers'

const Popup = (props) => {
  useEffect(() => {
    Cookies.set('kim_popup_seen', true, { expires: 1 })
  }, [])

  return (
    <VStack>
      <Paragraph
        heading="Webinar"
        subheading="The era of the ‘Democratization of document automation’ has arrived"
        htmlText={`<p>
          Thursday 6th October<br />
          8am PT | 10am CT | 11am ET | 4pm BST | 5pm CEST
        </p>`}
        hTier={3}
        tTier={2}
        align="center"
        buttons={[
          {
            to: 'https://attendee.gotowebinar.com/register/1347672927602135566?source=Website',
            type: LinkTypes.EXTERNAL,
            color: 'blue',
            label: 'Register here',
          },
        ]}
      />
    </VStack>
  )
}

export default Popup
