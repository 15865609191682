/** @jsx jsx */
import React, { useMemo, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { isFinite, get } from 'lodash'
import { motion, useMotionValue } from 'framer-motion'
import { mapRange } from 'canvas-sketch-util/math'
import { rgba } from 'polished'
import { useSetAtom } from 'jotai'

import Button from '../../button'
import { Links, useNavigationLinks } from '../../header'

import {
  Cont,
  FlexCols,
  FlexCol,
  FlexColEmpty,
  HStack,
  VStack,
} from '../../../styles/els'
import { valueFromTheme } from '../../../styles/utils'

import { overlayAtom } from '../index'

const Menu = (props) => {
  const { primary, secondary, tertiary } = useNavigationLinks()
  const setOverlay = useSetAtom(overlayAtom)

  return (
    <Cont
      sx={{
        pt: [3, null, null, 5],
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          pb: [5, null, null, 6],
        }}
      >
        <div
          sx={{
            px: 3,
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            setOverlay(null)
          }}
        >
          Close
        </div>
      </div>
      <div
        sx={{ display: 'flex', flexDirection: ['column', null, null, 'row'] }}
      >
        <Links
          pages={primary}
          color="background"
          textVariant="regular_28"
          showDropdown={true}
          sx={{
            flex: 1,
            alignItems: 'flex-start',
            flexWrap: 'wrap',
          }}
        />
        <div
          sx={{
            pl: [0, null, null, 4],
            pt: [4, null, null, 0],
          }}
        >
          <Button
            label="Try now for free"
            color="magenta"
            tier={3}
            type="try"
          />
        </div>
      </div>
      <VStack sx={{ py: [4, null, null, 6] }} space={0}>
        {secondary ? (
          <Links
            pages={secondary}
            color="b3"
            sx={{ width: ['100%', null, null, 'auto'] }}
          >
            <li
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                pb: [3, null, null, null, 0],
              }}
            >
              <Button label="Login" color="b6" tier={4} type="login" />
            </li>
          </Links>
        ) : null}
        {tertiary ? (
          <Links
            pages={tertiary}
            color="b6"
            sx={{ width: ['100%', null, null, 'auto'], flexWrap: 'wrap' }}
          />
        ) : null}
      </VStack>
    </Cont>
  )
}

export default Menu
