/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import _, { get } from 'lodash'

import Button from '../../button'
import { RatioBox } from '../../image'
import Link from '../../link'
import Paragraph from '../../paragraph'

import { VStack } from '../../../styles/els'
import { floodBgImage } from '../../../styles/css'

import { renderHTML } from '../../../lib/helpers'

import FlagEU from '../../../styles/icons/flag-eu.svg'
import FlagUK from '../../../styles/icons/flag-uk.svg'
import FlagUS from '../../../styles/icons/flag-usa.svg'

const getQueryPrefix = (key, prefix) => {
  return prefix ? `${prefix}${key.charAt(0).toUpperCase()}${key.slice(1)}` : key
}

export const useOverlayContent = (data, prefix = '') => {
  return useMemo(() => {
    const heading = get(data, getQueryPrefix('overlayHeading', prefix))
    const intro = get(data, getQueryPrefix('overlayIntro', prefix))
    const ctaText = get(data, getQueryPrefix('overlayCtaText', prefix))
    const links = get(data, getQueryPrefix('overlayLinks', prefix))
    return {
      heading,
      intro,
      ctaText,
      links,
    }
  }, [data, prefix])
}

const regionLinkData = {
  eu: {
    gaKey: 'eu',
    flag: FlagEU,
  },
  uk: {
    gaKey: 'uk',
    flag: FlagUK,
  },
  usa: {
    gaKey: 'usa',
    flag: FlagUS,
  },
}

const linkDataForRegion = (region) => {
  return get(regionLinkData, region)
}

const RegionOverlayContent = (props) => {
  const { heading, intro, ctaText, links, gaPrefix } = props

  return (
    <VStack space={[4, null, 6]} sx={{ maxWidth: '660px', mx: 'auto' }}>
      <Paragraph
        heading={heading}
        hTier={3}
        htmlText={intro}
        tTier={2}
        tTint={true}
      />
      {ctaText ? (
        <div
          sx={{
            variant: 'text.bold_19',
          }}
        >
          {ctaText}
        </div>
      ) : null}
      <div
        sx={{
          display: 'flex',
          flexDirection: ['column', null, 'row'],
          justifyContent: 'stretch',
          alignItems: 'stretch',
          mx: [0, null, -5],
        }}
      >
        {links && links.length > 0
          ? links.map((_link, _i) => {
              const linkData = linkDataForRegion(_link.region)

              return (
                <div
                  key={_i}
                  sx={{
                    flex: 1,
                    width: ['100%', null, `${(1 / links.length) * 100}%`],
                    px: [null, null, 5],
                    pb: [4, null, 0],
                  }}
                >
                  <Link
                    to={_link.link}
                    type="external"
                    gaEvents={
                      gaPrefix && linkData && linkData.gaKey
                        ? [`${gaPrefix}_click_out_${linkData.gaKey}`]
                        : null
                    }
                    sx={{
                      bg: 'b7',
                      display: 'block',
                      borderRadius: 2,
                      p: 5,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                      justifyContent: 'flex-end',
                      minHeight: '100%',
                    }}
                  >
                    <VStack space={3}>
                      {linkData && linkData.flag ? (
                        <div>
                          <RatioBox
                            ratio={1}
                            css={css`
                              ${floodBgImage}
                            `}
                            sx={{
                              backgroundImage: `url(${linkData.flag})`,
                              maxWidth: '40px',
                              mx: 'auto',
                            }}
                          />
                        </div>
                      ) : null}
                      <div
                        sx={{ textAlign: 'center', variant: 'text.bold_28' }}
                      >
                        {_link.label}
                      </div>
                      <Button
                        label="Choose"
                        color="b2"
                        textColor="light"
                        tier={4}
                        sx={{
                          textAlign: 'center',
                        }}
                      />
                    </VStack>
                  </Link>
                </div>
              )
            })
          : null}
      </div>
    </VStack>
  )
}

export default RegionOverlayContent
