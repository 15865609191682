import { css } from '@emotion/react'

import { fontFaceSrc } from './utils'

export const fontFace = (
  name,
  files = {},
  weight = 'normal',
  style = 'normal'
) => {
  return css`
    @font-face {
      font-family: ${name};
      ${fontFaceSrc(files, name)}
      font-weight: ${weight};
      font-style: ${style};
    }
  `
}

export const clearFix = css`
  clear: both;

  &:after {
    content: ' ';
    display: block;
    width: 100%;
    float: none;
    clear: both;
  }
`

export const hardwareAccelerate = css`
  transform: translate3d(0, 0, 0);
  content-visibility: auto;
  backface-visibility: hidden;
`

export const scrollY = css`
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`

export const scrollX = css`
  overflow: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`

export const fillArea = css`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`

export const fillScreen = css`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
`

export const floodBgImage = css`
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
`

export const gradientBg = (a, b) => {
  return css`
    background-color: ${a};
    background: linear-gradient(90deg, ${a} 0%, ${b} 100%);
  `
}
