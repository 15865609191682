import colors, { colorData } from './colors'
import { typeData, typeVariants } from './type'
import { breakpointData } from './breakpoints'

import { mathOnCss, mixColor, themeTextBlockPPadding, pxToVW } from '../utils'

const textBlockStyles = (size = 19) => {
  return {
    variant: `text.regular_${size}`,

    ['p, ol, ul, li']: {
      ...themeTextBlockPPadding(typeData.lineHeights[size], (v) =>
        Math.floor(v * 0.75)
      ),
    },

    ['b, strong']: {
      variant: `text.bold_${size}`,
    },

    ['a, .as-link']: {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'b4',
    },

    ['ol, ul']: {
      li: {
        position: 'relative',
        pl: `${size * 2}px`,
      },
    },

    ol: {
      li: {
        counterIncrement: 'ol-counter',

        '&:before': {
          content: 'counter(ol-counter)',
          position: 'absolute',
          left: 0,
          top: 0,
        },
      },
    },
  }
}

export default {
  // BREAKPOINTS
  breakpoints: [...breakpointData],

  // COLORS
  colors: {
    text: colorData.b9,
    background: colorData.b0,
    light: colorData.b9,
    dark: colorData.b0,
    white: colorData.b9,
    black: colorData.b0,
    ...colorData,
  },

  // SPACES
  space: [0, 4, 8, 12, 16, 20, 32, 40, 80, 120],

  // Z
  zIndices: [0, 1, 50, 1000],

  // RADIUS
  radii: [0, 8, 12, 24],

  // TYPOGRAPHY
  ...typeData,

  // VARIANTS
  text: {
    ...typeVariants,
  },

  textBlock: {
    default: {
      ...textBlockStyles(19),
    },
    28: {
      ...textBlockStyles(28),
    },
  },

  // STYLES
  styles: {
    root: {
      ...typeVariants.regular_19,
    },
  },

  useRootStyles: true,
  useColorSchemeMediaQuery: false,
  useCustomProperties: false,
}
