/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'
import { useSetAtom } from 'jotai'
import * as HoverCard from '@radix-ui/react-hover-card'

import Banner from '../banner'
import Button from '../button'
import ConditionalWrapper from '../conditional-wrapper'
import Link from '../link'
import Logo from '../logo'
import { overlayAtom } from '../overlays'

import { Cont } from '../../styles/els'

const query = graphql`
  query {
    primaryPages: allWpPage(
      sort: { menuOrder: ASC }
      filter: {
        acfPage: { navigationLevel: { in: ["primary"] } }
        acfPreview: { isWip: { ne: true } }
        parentId: { eq: null }
      }
    ) {
      nodes {
        title
        uri
        parentId
        template {
          templateName
        }
        acfTemplateRedirect {
          isParentLink
          url
        }
        wpChildren {
          nodes {
            uri
            ... on WpPage {
              title
              template {
                templateName
              }
              acfTemplateRedirect {
                isParentLink
                url
              }
            }
          }
        }
      }
    }
    secondaryPages: allWpPage(
      sort: { menuOrder: ASC }
      filter: {
        acfPage: { navigationLevel: { in: ["secondary"] } }
        acfPreview: { isWip: { ne: true } }
        parentId: { eq: null }
      }
    ) {
      nodes {
        title
        uri
        parentId
        template {
          templateName
        }
        acfTemplateRedirect {
          isParentLink
          url
        }
      }
    }
    tertiaryPages: allWpPage(
      sort: { menuOrder: ASC }
      filter: {
        acfPage: { navigationLevel: { in: ["tertiary"] } }
        acfPreview: { isWip: { ne: true } }
        parentId: { eq: null }
      }
    ) {
      nodes {
        title
        uri
        parentId
        template {
          templateName
        }
        acfTemplateRedirect {
          isParentLink
          url
        }
      }
    }
    wp {
      globalSettings {
        acfOptions {
          legalDocuments {
            name
            document {
              mediaItemUrl
              publicUrl
            }
          }
        }
      }
    }
  }
`

const mapPageLinks = (links) => {
  return links && links.length > 0
    ? links.map((_link, _i) => {
        const templateName = _link?.template?.templateName
        const children =
          _link?.title === 'Resources'
            ? [
                {
                  title: 'Blog',
                  uri: 'https://blog.kimdocument.com/blog',
                  external: true,
                },
                {
                  title: 'Case Studies & Use Cases',
                  uri: 'https://info.kimdocument.com/use-cases',
                  external: true,
                },
                {
                  title: 'eBooks',
                  uri: 'https://info.kimdocument.com/ebooks',
                  external: true,
                },
                {
                  title: 'Events & Webinars',
                  uri: 'https://info.kimdocument.com/events-webinars',
                  external: true,
                },
                {
                  title: 'Infographics',
                  uri: 'https://info.kimdocument.com/infographics',
                  external: true,
                },
                {
                  title: 'Press Releases',
                  uri: 'https://blog.kimdocument.com/press-release',
                  external: true,
                },
                {
                  title: 'Self-Service',
                  uri: 'https://info.kimdocument.com/self-service',
                  external: true,
                },
                {
                  title: 'Thought Leadership',
                  uri: 'https://info.kimdocument.com/thought-leadership',
                  external: true,
                },
                { title: 'Videos', uri: '/news/videos/' },
              ]
            : _link?.title === 'Solutions'
            ? [
                {
                  title: 'Campaign Functionality',
                  uri: 'https://info.kimdocument.com/campaign-functionality',
                  external: true,
                },
                {
                  title: 'Document Assembly',
                  uri: 'https://info.kimdocument.com/document-assembly',
                  external: true,
                },
                {
                  title: 'Document Automation',
                  uri: 'https://info.kimdocument.com/document-automation',
                  external: true,
                },
                {
                  title: 'Digital Transformation',
                  uri: 'https://info.kimdocument.com/digital-transformation',
                  external: true,
                },
                {
                  title: 'iManage Integration',
                  uri: 'https://info.kimdocument.com/imanage-integration',
                  external: true,
                },
                {
                  title: 'Template Library',
                  uri: 'https://info.kimdocument.com/template-library',
                  external: true,
                },
              ]
            : mapPageLinks(_link?.wpChildren?.nodes)

        return templateName === 'Redirect'
          ? {
              title: _link?.title,
              uri: _link?.acfTemplateRedirect?.isParentLink
                ? null
                : _link?.acfTemplateRedirect?.url,
              external: true,
              children,
            }
          : {
              title: _link?.title,
              uri: _link?.uri,
              external: false,
              children,
            }
      })
    : []
}

const mapLegalLinks = (links) => {
  return links && links.length > 0
    ? links.map((_doc, _i) => {
        return {
          title: _doc?.name,
          uri: get(_doc, 'document.publicUrl'),
          external: true,
        }
      })
    : []
}

export const useNavigationLinks = () => {
  const pageData = useStaticQuery(query)
  const data = useMemo(() => {
    const primaryPages = get(pageData, 'primaryPages.nodes')
    const secondaryPages = get(pageData, 'secondaryPages.nodes')
    const tertiaryPages = get(pageData, 'tertiaryPages.nodes')
    const legalDocuments = get(
      pageData,
      'wp.globalSettings.acfOptions.legalDocuments'
    )

    const primary = mapPageLinks(primaryPages)
    const secondary = []
    if (secondaryPages) {
      secondary.push(...mapPageLinks(secondaryPages))
    }
    secondary.push({
      type: 'contact',
      title: 'Contact us',
    })
    const tertiary = []
    if (tertiaryPages) {
      tertiary.push(...mapPageLinks(tertiaryPages))
    }
    if (legalDocuments && legalDocuments.length > 0) {
      tertiary.push(...mapLegalLinks(legalDocuments))
    }

    return {
      primary,
      secondary,
      tertiary,
    }
  }, [pageData])

  return data
}

const Dropdown = ({ children, page }) => {
  const [open, setOpen] = useState(false)
  return (
    <HoverCard.Root
      openDelay={200}
      open={open}
      onOpenChange={(v) => {
        setOpen(v)
      }}
    >
      <HoverCard.Trigger
        onClick={() => {
          setOpen(!open)
        }}
      >
        {children}
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          forceMount={true}
          sx={{
            zIndex: 3000,
            backgroundColor: 'b8',
            color: 'b1',
            minWidth: '240px',
            borderRadius: '8px',
          }}
          collisionPadding={4}
        >
          <Links pages={page.children} column={true} />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  )
}

export const Links = (props) => {
  const {
    pages,
    children,
    color,
    textVariant,
    showDropdown = false,
    column = false,
    ...otherProps
  } = props

  return (
    <ul
      sx={{
        variant: textVariant ? `text.${textVariant}` : 'text.regular_19',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: column
          ? 'flex-start'
          : ['flex-start', null, null, 'center'],
        flexWrap: 'nowrap',
        flexDirection: column ? 'column' : ['column', null, null, 'row'],
      }}
      {...otherProps}
    >
      {pages
        ? pages.map((_page, _i) => {
            const hasChildren =
              showDropdown && _page?.children && _page?.children?.length > 0

            return (
              <li key={_i}>
                <ConditionalWrapper
                  condition={!!hasChildren}
                  wrapper={(children) => {
                    return <Dropdown page={_page}>{children}</Dropdown>
                  }}
                >
                  <Link
                    to={_page.uri}
                    type={
                      _page.type
                        ? _page.type
                        : _page.external
                        ? 'external'
                        : null
                    }
                    sx={{
                      display: 'block',
                      px: [2, null, null, 3, null, 4],
                      pt: [2, null, null, '10px'],
                      pb: [2, null, null, '12px'],
                      color,
                    }}
                    as="div"
                  >
                    <span>{_page.title}</span>
                  </Link>
                </ConditionalWrapper>
              </li>
            )
          })
        : null}
      {children}
    </ul>
  )
}

const Header = (props) => {
  const { path } = props

  const { primary } = useNavigationLinks()

  const { theme } = useThemeUI()
  const setOverlay = useSetAtom(overlayAtom)

  return (
    <header
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: 3,
        pb: [3, null, null, 5],
        backgroundColor: 'background',
        color: 'text',
      }}
    >
      <Banner />
      <Cont
        sx={{
          pt: [3, null, null, 5, 0, 5],
        }}
      >
        <nav
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: [2, null, 0],
          }}
        >
          <Link
            to="/"
            sx={{
              display: 'block',
              width: '100%',
              maxWidth: ['64px', null, null, '96px'],
            }}
          >
            <Logo />
          </Link>
          <div
            sx={{
              flex: 1,
            }}
          />
          <div
            sx={{
              display: 'flex',
              flexDirection: ['column', null, null, null, null, 'row'],
              alignItems: 'flex-end',
            }}
          >
            <Links
              pages={primary}
              color="text"
              sx={{ display: ['none', null, null, null, 'flex'] }}
              showDropdown={true}
            />
            <Links color="text" sx={{ display: ['none', null, null, 'flex'] }}>
              <li
                sx={{
                  px: 3,
                }}
              >
                <Button
                  label="Login"
                  color="b4"
                  tier={4}
                  sx={{ mt: '5px' }}
                  type="login"
                />
              </li>
              <li
                sx={{
                  px: 3,
                }}
              >
                <Button
                  label="Try now for free"
                  color="white"
                  tier={4}
                  sx={{ mt: '5px' }}
                  type="try"
                />
              </li>
            </Links>
          </div>
          <div
            sx={{
              p: 3,
              ml: 4,
              mr: -3,

              '&:hover': {
                cursor: 'pointer',
              },

              '& > div': {
                width: '30px',
                height: '2px',
                backgroundColor: 'text',
                mb: 2,

                '&:last-child': {
                  mb: 0,
                },
              },
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              setOverlay('menu')
            }}
          >
            <div />
            <div />
            <div />
          </div>
        </nav>
      </Cont>
    </header>
  )
}

export default Header
