/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import { RatioBox } from '../image'

import { fillArea, floodBgImage } from '../../styles/css'

import LogoImg from './logo_dark.svg'

export const logoRatio = 82 / 150

const Logo = (props) => {
  const { ...otherProps } = props

  const ratio = logoRatio
  const img = LogoImg

  return (
    <RatioBox ratio={logoRatio} {...otherProps}>
      <div
        sx={{
          backgroundImage: `url(${img})`,
        }}
        css={css`
          ${fillArea};
          ${floodBgImage};
        `}
      />
    </RatioBox>
  )
}

export default Logo
