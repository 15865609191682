import React, { useEffect } from 'react'
import zenscroll from 'zenscroll'

export const scrollToEl = (el, time = 900) => {
  if (el) {
    zenscroll.to(el, time)
  }
}

const ScrollManager = () => {
  const headerHeight = 92
  useEffect(() => {
    zenscroll.setup(900, headerHeight)
  }, [])

  return null
}

export default ScrollManager
