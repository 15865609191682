/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, Embed } from 'theme-ui'
import { css } from '@emotion/react'
import { get, isFinite } from 'lodash'
import useFetch from 'use-http'
import { useSetAtom } from 'jotai'

import Button from '../button'
import { overlayAtom } from '../overlays'

import { renderHTML } from '../../lib/helpers'

import { fillArea } from '../../styles/css'
import { Cont, FlexCols, FlexCol } from '../../styles/els'
import ImageFill, { RatioBox, FillContainer } from '../image'
import { Placeholder } from 'gatsby-plugin-image'

const IFRAMELY_API_KEY = 'e0c425c265c8867c9eef59'

const EmbedThumbnail = (props) => {
  const { data, ...otherProps } = props
  const src = get(data, 'links.thumbnail.0.href')
  const image = {
    src,
    width: get(data, 'links.thumbnail.0.media.width'),
    height: get(data, 'links.thumbnail.0.media.height'),
  }

  return src ? <ImageFill image={image} {...otherProps} /> : null
}

const EmbedIframe = ({ html }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      css={css`
        ${fillArea}
      `}
      sx={{
        iframe: {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        },
      }}
    />
  )
}

const EmbedContent = (props) => {
  const { data, showSplash } = props

  const html = get(data, 'html')

  return !showSplash && html ? (
    <EmbedIframe html={html} />
  ) : (
    <EmbedThumbnail data={data} sx={{ zIndex: 1 }} />
  )
}

const EmbedCode = (props) => {
  const { code, placeholder, showSplash } = props

  return !showSplash && code ? (
    <EmbedIframe html={code} />
  ) : placeholder ? (
    <ImageFill image={placeholder} />
  ) : null
}

const EmbedSplit = (props) => {
  const {
    src,
    code,
    placeholder,
    data,
    byPassSplash,
    hasClickedPlayState: [hasClickedPlay, setHasClickedPlay],
    toEnlarge,
  } = props
  const setOverlay = useSetAtom(overlayAtom)

  const hideSplash = byPassSplash || hasClickedPlay

  return (
    <div
      css={css`
        ${fillArea}
      `}
      sx={{
        backgroundColor: 'b1',
        borderRadius: 1,
        overflow: 'hidden',
      }}
    >
      {!hideSplash ? (
        <>
          <div
            sx={{
              zIndex: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '&:hover': {
                cursor: 'pointer',
              },
            }}
            css={css`
              ${fillArea}
            `}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              if (toEnlarge) {
                setOverlay([
                  'video',
                  {
                    video: src,
                    embedCode: code,
                  },
                ])
              } else {
                setHasClickedPlay(true)
              }
            }}
          >
            <div
              sx={{
                backgroundColor: 'b0',
                opacity: 0.4,
                zIndex: 1,
              }}
              css={css`
                ${fillArea}
              `}
            />
            <Button
              color="white"
              label="Play"
              sx={{
                position: 'relative',
                zIndex: 2,
              }}
            />
          </div>
        </>
      ) : null}
      {src ? (
        <EmbedContent data={data} showSplash={!hideSplash} />
      ) : code ? (
        <EmbedCode
          code={code}
          showSplash={!hideSplash}
          placeholder={placeholder}
        />
      ) : null}
    </div>
  )
}

const EmbedWrapper = (props) => {
  const { src, contain, ratio: _ratio, byPassSplash, ...otherProps } = props
  const hasClickedPlayState = useState(false)
  const url = src
    ? `https://iframe.ly/api/iframely?url=${src}&api_key=${IFRAMELY_API_KEY}&autoplay=1`
    : null
  const { loading, error, data = [] } = useFetch(url, {}, [])
  const isLoading = loading || error

  const _embedRatio = get(data, 'links.player.0.media.aspect-ratio')
  const embedRatio = isFinite(_ratio)
    ? _ratio
    : isLoading || !isFinite(_embedRatio)
    ? 9 / 16
    : 1 / _embedRatio

  const embedContent = (
    <EmbedSplit
      {...props}
      hasClickedPlayState={hasClickedPlayState}
      ratio={embedRatio}
      data={isLoading ? null : data}
    />
  )

  return contain ? (
    <FillContainer
      size={{ width: 100, height: 100 * embedRatio }}
      contain={true}
    >
      {embedContent}
    </FillContainer>
  ) : (
    <RatioBox ratio={embedRatio}>{embedContent}</RatioBox>
  )
}

export default EmbedWrapper
