import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { get, isString } from 'lodash'
import htmr from 'htmr'

import Link from '../components/link'

export const formatImage = (image) => {
  if (!image) {
    return null
  }

  const _image =
    get(image, 'localFile.childImageSharp.gatsbyImageData') ||
    get(image, 'childImageSharp.gatsbyImageData') ||
    get(image, 'gatsbyImageData') ||
    image

  const gatsbyImage = getImage(_image)

  if (gatsbyImage) {
    return gatsbyImage
  }

  const publicURL = get(image, 'localFile.publicURL') || get(image, 'publicURL')
  const size = get(image, 'mediaDetails')

  if (publicURL && size) {
    return {
      src: publicURL,
      width: size.width,
      height: size.height,
    }
  }

  return null
}

export const formatVimeo = (vimeo) => {
  if (!vimeo) {
    return null
  }

  return {
    placeholder: formatImage(get(vimeo, 'placeholder')),
    vimeo: get(vimeo, 'vimeoId'),
    mux: get(vimeo, 'mux'),
  }
}

export const renderHTML = (str) => {
  return htmr(str, {
    transform: {
      a: (props) => {
        const { href, ...otherProps } = props
        if (href === `#${HashTriggers.POC}`) {
          return <Link type={LinkTypes.POC} {...otherProps} />
        } else if (href === `#${HashTriggers.TRY}`) {
          return (
            <Link type={LinkTypes.TRY} className="as-link" {...otherProps} />
          )
        } else {
          return <a href={href} {...otherProps} />
        }
      },
    },
  })
}

export const formatDate = (start, end) => {
  return `${isString(start) ? start : ''}${
    isString(start) && isString(end) ? ' — ' : ''
  }${isString(end) ? end : ''}`
}

export const LinkTypes = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  EMAIL: 'email',
  TRY: 'try',
  LOGIN: 'login',
  CONTACT: 'contact',
  POC: 'poc',
  VIDEO: 'video',
}

export const HashTriggers = {
  POC: 'poc',
  TRY: 'try',
}

export const OverlayTriggers = ['contact', 'try', 'login']

export const resolveButtons = (buttons) => {
  return buttons && buttons.length > 0
    ? buttons.map(({ type, label, gradientColor, ...linkProps }, _i) => {
        const _link = resolveLink(linkProps)
        return {
          color: gradientColor,
          label,
          ..._link,
        }
      })
    : []
}

export const resolveLink = (props) => {
  const { linksTo, email, externalLink, internalLink, video } = props

  let to = ''
  let data = null
  if (linksTo === LinkTypes.INTERNAL) {
    to = get(internalLink, '0.uri')
  } else if (linksTo === LinkTypes.EXTERNAL) {
    to = externalLink
  } else if (linksTo === LinkTypes.EMAIL && email) {
    to = `mailto:${email}`
  } else if (linksTo === LinkTypes.VIDEO && video) {
    data = {
      video,
    }
  }

  return {
    type: linksTo,
    to,
    data,
  }
}

export const ratioForImage = (ratioType) => {
  switch (ratioType) {
    case 'square':
      return 1
    case 'portrait':
      return 5 / 4
    case 'landscape':
      return 4 / 5
  }
}

export const addGAEvent = (eventKey = '', data = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventKey, data)
  }
}
