/** @jsx jsx */
import React, { useMemo, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import RegionOverlayContent, { useOverlayContent } from '../shared/regions'

const query = graphql`
  query {
    wp {
      globalSettings {
        acfOptions {
          tryOverlayHeading
          tryOverlayIntro
          tryOverlayCtaText
          tryOverlayLinks {
            label
            link
            region
          }
        }
      }
    }
  }
`

const Try = (props) => {
  const overlayData = useStaticQuery(query)
  const contentData = useOverlayContent(
    get(overlayData, 'wp.globalSettings.acfOptions'),
    'try'
  )

  return <RegionOverlayContent gaPrefix="try" {...contentData} />
}

export default Try
