/** @jsx jsx */
import React, { useMemo, useEffect, useState } from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { css } from '@emotion/react'
import { isFinite, get } from 'lodash'
import { motion, useMotionValue } from 'framer-motion'
import { mapRange } from 'canvas-sketch-util/math'
import { rgba } from 'polished'
import { Form, Input, TextArea, useFieldState } from 'informed'
import emailjs from '@emailjs/browser'

import Button from '../../button'
import { Links, legalPages } from '../../header'
import Paragraph from '../../paragraph'

import { VStack } from '../../../styles/els'
import { valueFromTheme } from '../../../styles/utils'

import { addGAEvent } from '../../../lib/helpers'
import HubspotComponent from '../../hubspot-component'

const Contact = (props) => {
  return (
    <>
      <Paragraph
        heading="Contact us"
        hTier={3}
        text={'Use the form below to contact us.'}
        tTier={2}
        sx={{
          pb: 7,
        }}
      />
      <HubspotComponent
        portalId="26235051"
        formId="a49c36c4-f9cf-43dd-b23a-f045e38d964e"
      />
    </>
  )
}

export default Contact
