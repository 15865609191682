/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect } from 'react'
import { get, indexOf } from 'lodash'
import WebFont from 'webfontloader'
import { useSetAtom } from 'jotai'
// import Cookies from 'js-cookie'

import Header from '../components/header'
import Overlays, { overlayAtom } from '../components/overlays'
import PageTransition from '../components/page-transition'
import ScrollManager from '../components/scroll-manager'

import { OverlayTriggers } from '../lib/helpers'

import Global from '../styles/global'

const Layout = (props) => {
  const { children, path, ...otherProps } = props
  const setOverlay = useSetAtom(overlayAtom)

  useEffect(() => {
    if (WebFont) {
      /*WebFont.load({
        typekit: {
          id: 'qbf7oug',
        },
        google: {
          families: ['Azeret Mono:400'],
        },
      })
      */
    }

    if (typeof window !== 'undefined' && window?.location?.hash) {
      const hash = get(window, 'location.hash')
      if (hash && hash.length > 1) {
        const _hash = hash.substring(1)
        if (indexOf(OverlayTriggers, _hash) >= 0) {
          setOverlay(_hash)
        }
      }
    }

    /*
    const popupSeen = Cookies.get('kim_popup_seen')
    let popupTO = null

    if (!popupSeen) {
      popupTO = setTimeout(() => {
        setOverlay('popup')
      }, 3000)
    }

    return () => {
      if (popupTO) {
        clearTimeout(popupTO)
      }
    }
    */
  }, [])

  return (
    <div sx={{ variant: 'text.regular_19' }}>
      <Global />
      <ScrollManager />
      <Header path={path} />
      <PageTransition path={path} {...otherProps}>
        {children}
      </PageTransition>
      <Overlays path={path} />
    </div>
  )
}

export default Layout
