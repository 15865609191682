import { buildColorObject } from '../utils'

const colors = {
  b0: [5, 10, 30],
  //b1: [39, 44, 63],
  b1: [45, 50, 70],
  b2: [69, 73, 94],
  b3: [87, 92, 113],
  b4: [126, 131, 153],
  b5: [160, 164, 187],
  b6: [197, 201, 226],
  b7: [217, 221, 246],
  b8: [230, 235, 255],
  b9: [241, 245, 255],
  aquaGreen: [0, 221, 40],
  aquaBlue: [0, 142, 255],
  magentaPurple: [150, 0, 250],
  magentaPink: [250, 0, 113],
  hotYellow: [255, 192, 0],
  hotRed: [255, 0, 0],
  blueLight: [0, 219, 255],
  blueDark: [0, 63, 255],
}

export default colors

export const colorData = buildColorObject(colors)
