/** @jsx jsx */
import React from 'react'
import { jsx, css as csx, Box } from 'theme-ui'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { isFinite, isArray } from 'lodash'

import { valueFromTheme, percForProps, mathOnThemeValue, pxToVW } from './utils'

const parsePadding = ({ space, theme, defaultSpace }) => {
  const padding = valueFromTheme(
    'padding',
    isArray(space) || isFinite(space)
      ? space
      : isFinite(defaultSpace)
      ? defaultSpace
      : 0,
    theme,
    { parse: true }
  )
  return isFinite(padding) ? `${padding}px` : padding
}

export const TextBlock = styled.div`
  ${csx({
    variant: 'textBlock.body',
  })};
`

export const Space = styled.div(({ space, theme }) => {
  const padding = parsePadding({ space, theme, defaultSpace: 5 })

  return csx({
    display: 'block',
    width: '100%',
    pb: padding,
  })
})

export const FullLayer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

export const Cont = styled.div`
  margin: 0px auto;
  max-width: 1480px;
  ${csx({
    width: [`100%`, null, null, null, null, `90%`],
    px: [3, null, 7],
  })}
`

export const FlexCol = styled.div(({ theme, ...otherProps }) => {
  const width = percForProps(otherProps)

  return csx({
    position: 'relative',
    width,
  })
})

export const FlexColEmpty = styled(FlexCol)`
  display: block;
`

export const FlexCols = styled.div(({ space, theme }) => {
  const padding = parsePadding({ space, theme, defaultSpace: 7 })
  const p = mathOnThemeValue(padding, (_v) => _v, {
    fallbackUnit: 'px',
  })
  const mB = mathOnThemeValue(p, (_v) => _v * -1)
  const hP = mathOnThemeValue(p, (_v) => _v * 0.5)
  const hPN = mathOnThemeValue(p, (_v) => _v * -0.5)

  return csx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    ml: hPN,
    mr: hPN,
    mb: mB,

    [`${FlexCol}, ${FlexColEmpty}`]: {
      pl: hP,
      pr: hP,
      pb: p,
    },
  })
})

export const HStack = styled.div(({ space, theme }) => {
  const padding = parsePadding({ space, theme, defaultSpace: 5 })

  return csx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flexWrap: 'wrap',

    '& > *': {
      mr: padding,

      '&:last-child': {
        mr: 0,
      },
    },
  })
})

export const VStack = styled.div(({ space, theme }) => {
  const padding = parsePadding({ space, theme, defaultSpace: 5 })

  return csx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    '& > *': {
      mb: padding,

      '&:last-child': {
        mb: 0,
      },
    },
  })
})
