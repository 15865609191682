import { buildTypeObject, buildTypeVariants, pxToVW } from '../utils'

const sansFallback = `'Helvetica Neue', Helvetica, Arial, sans-serif`

const styles = {
  regular: {
    font: `'Roobert-Medium', ${sansFallback}`,
    weight: 400,
  },
  bold: {
    font: `'Roobert-Bold', ${sansFallback}`,
    weight: 700,
  },
}

const sizes = {
  11: ['11px', '14px', '0px'],
  13: ['13px', '16px', '0px'],
  16: ['16px', '21px', '0px'],
  19: ['19px', '25px', '0px'],
  23: ['23px', '30px', '0px'],
  28: ['28px', '40px', '0px'],
  36: ['36px', '42px', '0px'],
  48: ['48px', '56px', '0px'],
  64: ['64px', '72px', '0px'],
  82: ['82px', '78px', '0px'],
}

const details = {
  normal: {
    textTransform: 'none',
  },
}

const responsiveSizes = {
  13: ['11', '13'],
  19: ['16', '19'],
  28: ['19', '23', null, '28'],
  48: ['28', '36', null, '48'],
  64: ['36', '48', null, '64'],
  82: ['48', null, null, '82'],
}

const variants = {
  regular_13: ['regular', responsiveSizes['13'], 'normal'],
  bold_13: ['bold', responsiveSizes['13'], 'normal'],
  regular_19: ['regular', responsiveSizes['19'], 'normal'],
  bold_19: ['bold', responsiveSizes['19'], 'normal'],
  regular_28: ['regular', responsiveSizes['28'], 'normal'],
  bold_28: ['bold', responsiveSizes['28'], 'normal'],
  regular_48: ['regular', responsiveSizes['48'], 'normal'],
  bold_48: ['bold', responsiveSizes['48'], 'normal'],
  bold_64: ['bold', responsiveSizes['64'], 'normal'],
  bold_82: ['bold', responsiveSizes['82'], 'normal'],
}

export const typeData = buildTypeObject(styles, sizes)
export const typeVariants = buildTypeVariants(variants, styles, sizes, details)

/*

(8) ["480px", "640px", "820px", "1024px", "1140px", "1440px", "1680px", "1920px"]*/
